<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { gsap } from 'gsap';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin'

gsap.registerPlugin(MorphSVGPlugin);

const mobileMenuOpen = ref(false),
      route = useRoute(),
      toggleNav = () => {
        mobileMenuOpen.value = !mobileMenuOpen.value
      }

watch(mobileMenuOpen, newVal => {
  gsap.to('.closed-state', {morphSVG: `.${newVal ? 'open' : 'closed'}-state`, duration: .3})
})

watch(
  route,
  to => {
    mobileMenuOpen.value = false
  }
)
</script>

<template>
  <nav class="main-nav">
    <button class="mobile-nav-toggle" type="button" @click="toggleNav">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 392">
          <path class="closed-state" d="M16,72H432a16,16,0,0,0,16-16V16A16,16,0,0,0,432,0H16A16,16,0,0,0,0,16V56A16,16,0,0,0,16,72Zm0,160H432a16,16,0,0,0,16-16V176a16,16,0,0,0-16-16H16A16,16,0,0,0,0,176v40A16,16,0,0,0,16,232Zm0,160H432a16,16,0,0,0,16-16V336a16,16,0,0,0-16-16H16A16,16,0,0,0,0,336v40A16,16,0,0,0,16,392Z"/>
          <path class="open-state" d="M298.3,196,409.74,84.56a35,35,0,0,0,0-49.54L385,10.26a35,35,0,0,0-49.54,0L224,121.7,112.56,10.26a35,35,0,0,0-49.54,0L38.26,35a35,35,0,0,0,0,49.54L149.7,196,38.26,307.44a35,35,0,0,0,0,49.54L63,381.74a35,35,0,0,0,49.54,0L224,270.3,335.44,381.74a35,35,0,0,0,49.54,0L409.74,357a35,35,0,0,0,0-49.54Z"/>
      </svg>
    </button>
    <ul class="link-list" :class="{'open': mobileMenuOpen}">
      <li>
        <router-link class="nav-link" :to="{name: 'start'}">start</router-link>
      </li>
      <li>
        <router-link class="nav-link" :to="{name: 'about'}">about</router-link>
      </li>
      <li>
        <router-link class="nav-link" :to="{name: 'services'}">services</router-link>
      </li>
      <li>
        <router-link class="nav-link"  :to="{name: 'contact'}">contact</router-link>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss">
@import '@sass/_variables.scss';
@import '@sass/_mixins.scss';

.main-nav {

  @media (max-width: $mobile-menu-max) {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }

  @media (min-width: $mobile-menu-max + 1) {
    display: block;
    padding-bottom: 3em;
  }

  .mobile-nav-toggle {

    @media (max-width: $mobile-menu-max) {
      background-color: $white;
      border: 0 none;
      bottom: calc(-40px - 1.6em);
      height: 40px;
      position: absolute;
      right: 1.6em;
      width: 40px;
      z-index: 2;

      svg {
        @include center-absolute;

        fill: $dark;
        height: auto;
        width: 30px;
      }
    }

    @media (min-width: $mobile-menu-max + 1) {
      display: none;
    }

    .open-state {
      visibility: hidden;
    }
  }

  .link-list {
    @include unlist;

    display: flex;

    @media (max-width: $mobile-menu-max) {
      background-color: $white-back;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding: 0 10%;
      position: fixed;
      right: -101%;
      top: 0;
      transition: right 300ms;
      width: 100%;
      z-index: 1;
    }

    @media (min-width: $mobile-menu-max + 1) {
      justify-content: center;
    }

    &.open {

      @media (max-width: $mobile-menu-max) {
        right: 0;
      }
    }
  }

  .nav-link {
    outline: none;
    text-decoration: none;
    text-transform: uppercase;

    @media (max-width: $mobile-menu-max) {
      background-color: $dark;
      border: 0 none;
      color: $white;
      display: block;
      margin-bottom: 2em;
      padding: 1em 1.4em;
    }

    @media (min-width: $mobile-menu-max + 1) {
      display: inline-block;
      margin: 0 1em;
    }

    &.router-link-exact-active {

      @media (max-width: $mobile-menu-max) {
        cursor: default;
        opacity: .5;
      }
    }
  }
}
</style>
